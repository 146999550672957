import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css"
import logo from '../img/BHB_websiteheadergif.gif'
import pic1 from '../img/babes/ghadeer.jpg'
import pic2 from '../img/babes/maggie.jpg'
import pic3 from '../img/babes/stephanie.jpg'
import pic4 from '../img/babes/halie.jpg'
import pic5 from '../img/babes/midori.jpg'
import pic6 from '../img/babes/jen.jpg'
import pic7 from '../img/babes/batsheba.jpg'
import pic8 from '../img/babes/kathleen.jpg'
import pic9 from '../img/babes/maddy.jpg'
import pic10 from '../img/babes/randah.jpg'
import pic11 from '../img/babes/taylor.jpg'

export const OurStoryPageTemplate = ({
  image,
  image2,
  image3,
  title,
  paragraph,
  title2,
  paragraph2,
  stat1,
  desc1,
  stat2,
  desc2,
  stat3,
  desc3,
  stat4,
  desc4
}) => (
  <>
    <section className="has-background-warning">
      <div className="has-text-centered">
        <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} offset={0}>
          <img src={logo} alt="Babes Helpin' Babes" width="100%" />
        </ScrollAnimation>
      </div>
    </section>
    <section>
      <div className="columns is-desktop">
        <div className="column is-full-mobile is-half-tablet">
          <div className="section" style={{
            maxWidth: '700px',
            margin: '0 auto'
          }}>
            <h1>{title}</h1>
            <p>{paragraph}</p>
            <h1 className="mt-6">{title2}</h1>
            <p>{paragraph2}</p>
          </div>
        </div>
        <div className="column is-full-mobile is-half-tablet p-0">
          <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} style={{ height: '100%', width: '100%' }}>
            <div
              className="full-width-image-container margin-top-0"
              style={{
                backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image
                  })`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100%', minHeight: '400px'
              }}
            ></div>
          </ScrollAnimation>
        </div>
      </div>
    </section>
    <section className="has-background-info py-5">
      <div className="container py-5">
        <div className="columns is-centered py-5">
          <ScrollAnimation className="column is-3 has-text-centered" animateIn="animate__bounceInRight" duration={1.25}>
            <div>
              <h1 className="alt1 has-text-warning">{stat1}</h1>
              <p className="has-text-white px-5 has-text-weight-bold">{desc1}</p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="column is-3 has-text-centered" animateIn="animate__bounceInRight" duration={1} delay={500}>
            <div>
              <h1 className="alt1 has-text-warning">{stat2}</h1>
              <p className="has-text-white px-5 has-text-weight-bold">{desc2}</p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="column is-3 has-text-centered" animateIn="animate__bounceInRight" duration={0.75} delay={1000}>
            <div>
              <h1 className="alt1 has-text-warning">{stat3}</h1>
              <p className="has-text-white px-5 has-text-weight-bold">{desc3}</p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="column is-3 has-text-centered" animateIn="animate__bounceInRight" duration={0.5} delay={1250}>
            <div>
              <h1 className="alt1 has-text-warning">{stat4}</h1>
              <p className="has-text-white px-5 has-text-weight-bold">{desc4}</p>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </section>
    <section className="has-background-primary py-5">
      <div className="container py-5">
        <div className="columns is-centered py-5">
          <div className="column is-11">
            <div className="section has-text-centered">
              <ScrollAnimation animateIn="animate__fadeIn" duration={0.5}>
                <h1>
                  After more than three years of growing Babes Helpin' Babes, <mark className="has-text-white">we recognized a larger industry need.</mark> In July of 2020, our founder, Abby Kallenbach, decided to leave her full-time position at Signal Theory to pursue a career in diversity consulting.
                </h1>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="columns is-desktop is-vcentered">
        <div className="column is-full-mobile p-0 has-text-centered">
          <ScrollAnimation animateIn="animate__fadeIn" duration={0.5}>
            <img style={{ maxWidth: '450px' }} src={`${!!image2.childImageSharp ? image2.childImageSharp.fluid.src : image2}`} alt="" />
          </ScrollAnimation>
        </div>
        <div className="column is-full-mobile is-three-fifths">
          <div className="section is-flex is-flex-direction-column is-justify-content-center is-align-items-start" style={{
            maxWidth: '700px',
            minHeight: '800px',
            margin: '0 auto 0 0'
          }}>
            <h6 className="large">ABBY KALLENBACH</h6>
            <h6 className="large mb-3 is-italic">Founder and CEO</h6>

            <p>Abby is a creative and diversity consultant from Kansas City, MO. She started her career in advertising as an Art Director, but quickly realized that the workplace she loved was in need of serious changes. </p>

            <p>There was not only a lack of women in leadership positions, but a severe and startling lack of Black, Indigenous and people of color (BIPOC) at every level. And nothing seemed to change because nobody talked about it.</p>

            <p>Today she works to call out injustices, challenge the status quo and ensure that women and BIPOC are represented in the workplace.
</p>

            <Link to="/contact" className="btn btn-one ">WORK WITH ABBY</Link>
          </div>
        </div>
      </div>
    </section>
    <section className="has-background-white">
      <ScrollAnimation animateIn="animate__fadeIn" duration={0.5} style={{ height: '100%', width: '100%' }}>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url(${!!image3.childImageSharp ? image3.childImageSharp.fluid.src : image3
              })`, backgroundSize: 'cover', backgroundPosition: 'center 40%', height: '100%', minHeight: '350px'
          }}
        ></div>
      </ScrollAnimation>
    </section>
    <section className="py-5">
      <div className="container py-5">
        <div className="columns is-centered py-5">
          <div className="column is-10">
            <div className="section has-text-centered">
              <h1>Our Team</h1>
              <p>We started Babes Helpin’ Babes as a small creative group at a single agency. Our mission has spread because of consultants, moderators and designers who believe in the work we are doing and wish to challenge and change the creative industry. All of our volunteers and contract workers have experience in the creative industry and are able to bring their expertise and personal experiences to our content and services. We're always looking to add diversity to our team, if you'd like to work with us, please reach out. </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-6">
      <div className="container py-6">
        <div className="columns is-centered py-5">
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic1} alt="Babes Helpin' Babes" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>GHADEER GARCIA</h6>
              <h6>Consultant + Moderator | KC</h6>
            </div>
          </div>
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic2} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>MAGGIE HYLTON</h6>
              <h6>Consultant + Moderator | KC</h6>
            </div>
          </div>
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic3} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>STEPHANIE VALLE</h6>
              <h6>Consultant + Moderator | CHI</h6>
            </div>

          </div>
        </div>
        <div className="columns is-centered">
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic4} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>HALIE BRANSON</h6>
              <h6>Designer | Springfield</h6>
            </div>
          </div>
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic5} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>MIDORI SAITO</h6>
              <h6>Designer | KC</h6>
            </div>
          </div>
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic6} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>JEN GUIMARÃES</h6>
              <h6>Designer | KC</h6>
            </div>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic7} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>BATSHEBA CASTRO MARTINEZ</h6>
              <h6>Designer | KC</h6>
            </div>
          </div>
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic8} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>KATHLEEN MURRAY</h6>
              <h6>Designer | KC</h6>
            </div>
          </div>
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic9} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>MADDY BEST</h6>
              <h6>Designer | KC</h6>
            </div>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic10} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>RANDAH O'DOHERTY</h6>
              <h6>Designer | KC</h6>
            </div>
          </div>
          <div className="card column is-3">
            <div className="card-image">
              <figure class="image is-3by4">
                <img src={pic11} alt="Babes Helpin' Babes" width="100%" style={{ objectFit: 'cover' }} />
              </figure>
            </div>
            <div className="card-content">
              <h6>TAYLOR MIZERA</h6>
              <h6>Designer | KC</h6>
            </div>
          </div>
          <div className="card column is-3"></div>
        </div>
      </div>
    </section>
  </>
)

OurStoryPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  paragraph: PropTypes.string,
  title2: PropTypes.string,
  paragraph2: PropTypes.string,
  stat1: PropTypes.string,
  desc1: PropTypes.string,
  stat2: PropTypes.string,
  desc2: PropTypes.string,
  stat3: PropTypes.string,
  desc3: PropTypes.string,
  stat4: PropTypes.string,
  desc4: PropTypes.string,
}

const OurStoryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <OurStoryPageTemplate
        image={frontmatter.image}
        image2={frontmatter.image2}
        image3={frontmatter.image3}
        title={frontmatter.title}
        paragraph={frontmatter.paragraph}
        title2={frontmatter.title2}
        paragraph2={frontmatter.paragraph2}
        stat1={frontmatter.stat1}
        stat2={frontmatter.stat2}
        stat3={frontmatter.stat3}
        stat4={frontmatter.stat4}
        desc1={frontmatter.desc1}
        desc2={frontmatter.desc2}
        desc3={frontmatter.desc3}
        desc4={frontmatter.desc4}
      />
    </Layout>
  )
}

OurStoryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default OurStoryPage

export const OurStoryPageQuery = graphql`
  query OurStoryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        paragraph
        title2
        paragraph2
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stat1
        desc1
        stat2
        desc2
        stat3
        desc3
        stat4
        desc4
        image2 {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
